export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-square150x150logo","content":"/mstile-150x150.png"},{"name":"facebook-domain-verification","content":"vif7g6cq1x1plrg7wr39zjoxupakgy"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"16x16","type":"image/vnd.microsoft.icon"},{"rel":"icon","href":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"rel":"icon","href":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"rel":"icon","href":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","sizes":"any","type":"image/svg+xml","color":"#662E9C"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"style":[],"script":[{"key":"yt-embed","src":"https://www.youtube.com/iframe_api","tagPriority":0,"tagPosition":"head"},{"key":"url-change","src":"/redesign/js/url-change.js","async":true,"tagPriority":1,"tagPosition":"head"},{"key":"pardot","src":"/redesign/js/pardot.js","defer":true,"tagPosition":"head"},{"key":"osano","src":"https://cmp.osano.com/16CY2jU8Uv83w1BCJ/f3e6763e-8d22-4fa5-b465-db2200ea3d1c/osano.js","tagPosition":"bodyClose","async":"true"},{"key":"Chatlio","id":"Chatlio","src":"https://js.chatlio.com/widget.js","async":true}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false