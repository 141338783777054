<template>
  <li data-component-name="MenuResourcesPartial">
    <ul class="list">
      <li>
        <NavItem :to="localePathWithSlash(PATHS.GUIDES.path)">
          {{ $t(PATHS.GUIDES.i18nKey) }}
        </NavItem>
      </li>
      <li>
        <NavItem :to="LINKS.OBJECT_FIRST.YOUTUBE" target="_blank">
          {{ $t('Videos') }}
        </NavItem>
      </li>
      <li>
        <NavItem :to="localePathWithSlash(PATHS.EVENTS.path)">
          {{ $t(PATHS.EVENTS.i18nKey) }}
        </NavItem>
      </li>
    </ul>
  </li>
</template>

<script lang="ts" setup>
// components
import NavItem from '~/components/redesign/NavItem.vue';

// constants
import { PATHS } from '~/constants/nav-items.js'
import LINKS from '~/constants/links.js'

// composables
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

const localePathWithSlash = useLocalePathWithSlash();
</script>
