<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>

  <AppOverlay id="overlay" v-bind="overlay" />
  <AppLoader v-if="isLoading" />

  <ClientOnly>
    <TheChatlio />
  </ClientOnly>
</template>

<script setup lang="ts">
// components
import AppLoader from '~/components/redesign/AppLoader.vue';
import AppOverlay from '~/components/redesign/AppOverlay.vue';
import TheChatlio from '~/components/redesign/TheChatlio.vue'

// composable
import { useI18n } from '#i18n';
import { storeToRefs } from 'pinia';
import { useAbsoluteUrl } from '~/composables/absoluteUrl';

// store
import useUIStore from '~/stores/ui';
import { useCommonStore } from '~/stores/common';

// services
import { IpInfoCookieComponent } from '~/components/services/IpInfoCookieComponent';

const route = useRoute();
const { locale } = useI18n();
const { getAbsoluteUrl } = useAbsoluteUrl();

const uiStore = useUIStore();
const { isLoading, overlay, isScrollLocked } = storeToRefs(uiStore);

const commonStore = useCommonStore();
const { requestIpInfo, setIpInfo } = commonStore;
const { getIpInfoCookie, setIpInfoCookie } = IpInfoCookieComponent();

if (import.meta.client) {
  const existingData = getIpInfoCookie();

  if (!existingData) {
    const { pending, data } = await requestIpInfo();

    watch(pending, processing => {
      if (processing) return;

      setIpInfoCookie(data.value)
      setIpInfo(data.value);
    });
  } else {
    setIpInfo(JSON.parse(existingData))
  }
}

useHead({
  link: [{ rel: 'canonical', href: getAbsoluteUrl(route.path) }],
  meta: [{ property: 'og:image', content: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' }],
  htmlAttrs: {
    lang: locale,
  },
  bodyAttrs: {
    class: {
      'no-scroll': isScrollLocked,
    },
  },
});

useSchemaOrg([
  defineWebSite({ image: 'https://objectfirst.nyc3.digitaloceanspaces.com/Metadata/OG_Banner_General_360x169.png' }),
  defineImage({
    url: 'https://objectfirst.b-cdn.net/uploads/logos/logo-white.svg',
    width: 103,
    height: 51,
    caption: "Object First"
  })
]);
</script>

<style scoped lang="scss">
[data-component-name="AppOverlay"] {
  padding: 1rem;
}
</style>
